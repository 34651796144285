.root {
    flex-basis: 320px;
}

.content {
    padding: 1em 2em 3em;
}

.heading {
    display: flex;
    align-items: center;
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgb(235, 235, 235);
    padding: .6em 2em .6em;
}

.heading > .left {
    margin-right: auto;
}

.heading .filterDropdown {
    margin-right: 1em;
    min-width: 10em;
}

a {
    cursor: pointer;
}