@import-normalize;
html {
  min-height: 100%;
}

html, body, #root, #root > div { 
  display: flex;
  flex-grow: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img { 
  max-width: 100%; 
}

#root > div { 
  display: flex; 
  flex-direction: column; 
}

@media print {
  header, .PortalNav {
    display: none !important;
  }
}
