.root {
    flex-basis: 320px;
}

.rootUsers { 
    max-width: 1000px; 
    width: 90%;
    margin: 0 auto;
    padding: 2em 0 3m;
}

.content {
    padding: 1em 2em 3em;
}

.heading {
    display: flex;
    align-items: center;
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgb(235, 235, 235);
    padding: .6em 2em .6em;
}

.heading > .left {
    margin-right: auto;
}

.heading .filterDropdown {
    margin-right: 1em;
    width: 10em;
}

a {
    cursor: pointer;
}

.recipe {
    padding: 2.5em 0;
    border-bottom: 1px solid #cecece;
}

.recipe h2 {
    margin-top: 0;
}

table td {
    padding: .4em .2em;
}