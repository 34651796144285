.root { 
    max-width: 640px;
    width: 90%;
    margin: 0 auto;
    padding: 2em 0 3em;
}

.root h2 { margin-top: 2.5em; }

.multilineText { white-space: pre; }

.field { margin: .7em 0; }
.fieldGroup { margin: .5em 0; display: flex; }
.fieldGroup .field { margin: 0 1em 0 0; }
.fieldGroup .field:last-of-type { flex-grow: 1; margin-right: 0; }

.group {
    margin-top: 4.5em;
}

hr { 
    margin: 1.5em 0;
    border: 1px solid #cecece;
}

.historyFooter button {
    margin-right: .7em;
}

.footer { margin: 2.5em 0 5em; }
.footer > :first-child { margin-right: 1em; }