.root { 
    max-width: 640px;
    width: 90%;
    margin: 0 auto;
    padding: 2em 0 3em;
}

.root h2 { margin-top: 2.5em; }

.field { margin: .7em 0; }
.fieldGroup { margin: .5em 0; display: flex; }
.fieldGroup .field { margin: 0 1em 0 0; flex-grow: 1; }
.fieldGroup .field:last-of-type { margin-right: 0; }

.group {
    margin-top: 4.5em;
}

.group > div { 
    margin: .5em 0;
}

.ages > div > div {
    display: flex;
    flex-wrap: wrap;
}

.ages > div > div > div {
    flex: 0 1 10em;
}

.zfg {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #cecece;
}

.zfg tr:nth-of-type(2n+1) {
    background: #f1f1f1;
}

.zfg tr td {
    padding: .5em;
}

.notice { 
    margin-top: 3.5em; 
}

.footer { margin: 2.5em 0 5em; }
.footer > :first-child { margin-right: 1em; }

@media print {
    .footer {
        display: none;
    }
}