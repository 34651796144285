.root {
    height: 100%;
}

.content {
    max-width: 640px; margin: 0 auto;
    padding: 2em;
    background: rgba(255, 255, 255, 0.9);
}

h1 small {
    font-weight: 400;
}

.desc { line-height: 1.4em; }
.form .question { margin: 2em 0; }
.form .question > div > div > label { font-size: 1.2em; }
.form .question textarea { display: block; width: 100%; }

.multiselectLabel {
    font-size: 1.2em;
}

.multiselectOption {
    margin: .5em 0;
}

.footer { margin: 2.5em 0 5em; }
.footer > :first-child { margin-right: 1em; }
