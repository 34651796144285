* {
  box-sizing: border-box;
}

.header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  background: #231f20;
  color: #ffffff;
}

.header span {
  padding: .5em 1em;
}

.header span:hover {
  cursor: pointer; 
  color: #d5031c;
}

.header .left {
  margin-right: auto;
}

.header .logo {
  max-height: 4em;
}

main {
  flex-grow: 1;
}

.adminView {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 1em;
}

.leftbar {
  flex: 0 0 200px;
  border-right: 1px solid #cecece;
}
.leftbarContent{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 100px);
}

.leftbarDonwContent {
  display: flex;
  justify-content: flex-end;
}
.showNav{
  max-width: 500px;
}
.hideNav {
  max-width:  35px;
}

.adminView > div:nth-of-type(2) {
  flex-grow: 1;
}

.faq {
  width: 800px;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 1.5em;
}

.faq h2 { 
  margin-top: 2em;
}

.floatingButton {
  display: block;
  width: 1.4em;
  height: 1.4em;
  padding-top: .1em;
  position: fixed;
  bottom: 1em;
  right: 1em;
  background: #d5031c;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 2.5em;
  font-weight: bold;
}