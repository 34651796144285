.root {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #eee;
}

.logo {
    max-width: 90%;
    max-height: 15em;
    margin-bottom: 5em;
    padding: 1em .5em;
}

.form {
    width: 320px;
    background: #ffffff;
    border: 1px solid #c5c5c5;
}

.form .padBox {
    padding: 2.5em;
}

.form .padBox > h2 {
    margin-top: 0;
}

.form .padBox > div {
    margin-bottom: 1em;
}

.form .padBox > button {
    margin-top: 1em;
}

.footer {
    color: #fff;
}

.footer span {
    text-decoration: underline;
    cursor: pointer;
}

.footer strong {
    text-transform: uppercase;
    letter-spacing: .05em;
}

.footer.down {
    position: absolute;
    bottom: 1em;
}

.footer a {
    color: #ffffff;
    text-decoration: none; 
    cursor: pointer;
}

.footer a:hover {
    text-decoration: underline;
}