.root {
    padding: 2em 5% 0;
    height: 100%;
    background: url(./images/dashboard.jpg) right 0/auto 100% no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.textbox {
    padding: 2em;
    background: rgba(255, 255, 255, .8);
}

.root h1 {
    margin-top: 0;
    font-size: 3em;
    font-weight: normal;
    text-align: center;
}

.root .intro {
    margin: 3em auto 5em;
    max-width: 804px;
}

.nav { 
    margin: 0 auto;
    max-width: 846px;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    flex-wrap: wrap;
}

.nav .box { 
    cursor: pointer;
    width: 240px; 
    margin: 1.5em; 
    padding: 1.5em; 
    background: #fff;
    box-shadow: 5px 5px 20px lightgrey; 
    text-decoration: none; 
    text-align: center; 
    color: inherit; 
    text-transform: uppercase; 
    letter-spacing: .05em; 
    color: #666; 
}

.nav .box:hover { color: inherit; }

.nav .box .icon {
    display: block; 
    font-size: 100px;
    margin-bottom: 2rem;
}

.nav .box span {
    font-size: 1.2em;
    font-weight: 600;
}