.root {
    flex-basis: 320px;
    padding: 2em 2em 3em;
}

.heading {
    display: flex;
    align-items: center;
}

.heading h1 {
    margin-right: auto;
}

.heading .search {
    margin-right: 1em;
}

a { 
    cursor: pointer;
}

.position { 
    display: flex; 
    padding: .5em 0
}

.position .name {
    width: 12em; 
}

.position .progress {
    flex-grow: 1; 
}

.flexi {
    display: flex;
    flex-wrap: wrap;
}

.flexi > div {
    text-align: center;
}

.flexi img {
    max-width: 85%;
}

.dark {
    margin-left: 1em; 
    display: inline-block;
    width: 1em;
    height: 1em;
    background: #169e69;
}

.light {
    margin-left: 1em; 
    display: inline-block;
    width: 1em;
    height: 1em;
    background: #2ACF82;
}

.barNav {
    display: flex;
    margin-bottom: 1.4em;
}

.barNav .middle {
    margin: 0 auto;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.barNav .middle p {
    margin-right: 1em;
}

.barNav .middle .filterDropdown {
    min-width: 10em;
}