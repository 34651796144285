.root {
    max-width: 860px;
    width: 90%;
    margin: 0 auto;
    padding: 2em 0 3em;
}

.root h2 { margin-top: 2.5em; }

.step {
    margin: 1.5em 0 .5;
}

.step.completed, .step.granted {
    opacity: 0.8;
}

.step .content {
    margin: .5em 0 .5em .5em;
    padding: .5em 0 1.5em 1em;
    border-left: 2px dashed #cecece;
}

.step.completed .content, .step.completed .granted {
    border-left: 2px solid #cecece;
}

.row > * {
    margin-right: .7em;
    margin-bottom: .7em;
}

.field { margin: .7em 0; }
.fieldGroup { margin: .5em 0; display: flex; }
.fieldGroup .field { margin: 0 1em 0 0; flex-grow: 1; }
.fieldGroup .field:last-of-type { margin-right: 0; }

.group {
    margin-top: 4.5em;
}

.infoBox { border: 1px solid #cecece; padding: .7em; }

.footer { margin: 2.5em 0 5em; }
.footer > :first-child { margin-right: 1em; }

.blocker { margin: 0; min-width: 800px}

.multiselectLabel {
    font-weight: 600;
}

.multiselectOption {
    margin: .5em 0;
}
